export interface UserStoreProps {
    id: number;
    fullName?: string;
    userTypeId: number;
    email?: string;
    phone?: string | null;
    profilePicture?: string;
    emailVerified?: boolean;
    createdAt?: string;
    updatedAt?: string;
    userCredit?: {
        credits: number;
    };
}

export interface UserStoreContextState {
    user: UserStoreProps;

    setUserState: (user: UserStoreProps) => void;
}

export const defaultUserStoreState: UserStoreContextState = {
    user: {
        id: 0,
        userTypeId: 1,
    },
    setUserState: () => {},
};
