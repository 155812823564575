import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { Link } from '@repo/ui';
import { logoutUser } from '@/store/mutable-data/user/actions';

export const userMenuItems: {
    label: string;
    route?: any;
    icon: JSX.Element;
    component?: any;
    onClick?: any;
    divider: boolean;
}[] = [
    {
        label: 'Settings',
        route: () => '/settings',
        icon: <Settings fontSize="medium" style={{ color: '#fff' }} />,
        component: Link,
        onClick: null,
        divider: false,
    },
    {
        label: 'Logout',
        icon: <Logout fontSize="medium" style={{ color: '#fff' }} />,
        onClick: async (userId: number, callback: any) => {
            await logoutUser(userId, callback);
        },
        divider: false,
    },
];

export const resourceMenuItems = [{ name: 'Blogs', route: '/blog' }];
